<mat-sidenav-container class="app-content">
  <mat-sidenav
      #appSideNav
      [mode]="viewportMobileQuery.matches ? 'over' : 'side'"
      [opened]="(isMenuBeOpenedOnSide | async)"
      [fixedInViewport]="viewportMobileQuery.matches"
  >
    <mat-toolbar color="primary">
      <mat-toolbar-row>
        <h1>Menu</h1>
        <span class="fill-space"></span>
        <button mat-icon-button *ngIf="viewportMobileQuery.matches" (click)="appSideNav.close()">
          <mat-icon>arrow_back</mat-icon>
        </button>
      </mat-toolbar-row>
    </mat-toolbar>
    <mat-nav-list>
      <!-- Default Menu -->

      <a mat-list-item class="side-nav-item"
         (click)="navClicked(link)"
         *ngFor="let link of navLinks"
         [routerLink]="link.link"
         routerLinkActive="active" #rla="routerLinkActive">

        <fa-icon mat-list-icon class="icon" *ngIf="link.icon" [icon]="link.icon"></fa-icon>{{link.label | translate }}
      </a>

      <!-- Event Organiser Menu -->

      <a mat-list-item  *ngIf="isOrganiser | async" (click)="showOrganiserMenu = !showOrganiserMenu">
        <fa-icon mat-list-icon class="icon" icon="sliders-h"></fa-icon> <span translate>Organiser</span>
        <mat-icon *ngIf="!showOrganiserMenu">chevron_right</mat-icon>
        <mat-icon *ngIf="showOrganiserMenu">expand_more</mat-icon>
      </a>
      <mat-nav-list *ngIf="(isOrganiser | async) && showOrganiserMenu" class="sidenav-submenu">
        <a mat-list-item *ngFor="let orgLink of organiserLinks"
           (click)="navClicked(orgLink)"
           [routerLink]="orgLink.link">
          <fa-icon mat-list-icon class="icon" [icon]="orgLink.icon"></fa-icon>
          {{ orgLink.label | translate }}</a>
      </mat-nav-list>


      <!-- Admin Menu -->

      <a mat-list-item  *ngIf="isAdmin | async" (click)="showAdminMenu = !showAdminMenu">
        <fa-icon mat-list-icon class="icon" icon="tools"></fa-icon> <span translate>Admin</span>
        <mat-icon *ngIf="!showAdminMenu">chevron_right</mat-icon>
        <mat-icon *ngIf="showAdminMenu">expand_more</mat-icon>
      </a>
      <mat-nav-list *ngIf="(isAdmin | async) && showAdminMenu" class="sidenav-submenu">
        <a mat-list-item *ngFor="let admLink of adminLinks"
           (click)="navClicked(admLink)"
           [routerLink]="admLink.link">
          <fa-icon mat-list-icon class="icon" [icon]="admLink.icon"></fa-icon>
          {{ admLink.label | translate }}</a>
      </mat-nav-list>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content class="main-content">
    <mat-toolbar color="primary" class="main-toolbar">
      <mat-toolbar-row>
        <button mat-icon-button (click)="navigationService.navigateBack()" *ngIf="(navigationService.lastNavigationAction() | async)">
          <mat-icon>arrow_back</mat-icon>
        </button>
        <button mat-icon-button (click)="appSideNav.toggle()" *ngIf="!(navigationService.lastNavigationAction() | async) && canMenuBeOpenedOnSide | async">
          <mat-icon>menu</mat-icon>
        </button>
        <div class="app-brand">
          <span *ngIf="!(navigationService.lastNavigationAction() | async) || !(navigationService.lastNavigationAction() | async).headerText" translate>Bibchip</span>
          <ng-container *ngIf="(navigationService.lastNavigationAction() | async) as lastNavigation">
            <span>{{lastNavigation.headerText}}</span>
          </ng-container>
        </div>
        <span class="fill-space"></span>
        <div *ngIf="!sideNav.opened && !(canMenuBeOpenedOnSide | async)" fxShow="true" fxHide.lt-md="true">
          <a mat-button
             (click)="navClicked(link)"
             *ngFor="let link of navLinks"
             [routerLink]="link.link"
             routerLinkActive #rla="routerLinkActive">
            <fa-icon *ngIf="link.icon" [icon]="link.icon"></fa-icon>
            &nbsp;  <span [class.mat-active]="rla.isActive">
            {{link.label | translate }}</span>
          </a>
            <!-- <a mat-button *ngIf="isAdmin | async" [matMenuTriggerFor]="adminMenu">
              <fa-icon icon="tools"></fa-icon>&nbsp; <span translate>Admin</span>
            </a> -->
            </div>
        <mat-icon class="language-icon" [matMenuTriggerFor]="languageMenu" aria-hidden="false" aria-label="Language">language</mat-icon>
        <mat-menu #languageMenu="matMenu">
          <button mat-menu-item (click)="changeLanguage('en')">
            <span>English</span>
          </button>
          <button mat-menu-item (click)="changeLanguage('be')">
            <span>Nederlands</span>
          </button>
        </mat-menu>
      </mat-toolbar-row>
    </mat-toolbar>
    <main class="main-content-inner">
      <div *ngIf="!fullScreenRequested" class="home-picture">
        <div class="home-pic-overlay"></div>
        <img class="home-pic" [src]="'/assets/images/solo-runner-small.jpg'" />
        <div class="home-pic-overlay-text">
          <div class="title"> <fa-icon icon="stopwatch"></fa-icon> Bibchip </div>
          <div class="subtitle">Uitslagen en voorinschrijvingen</div>
        </div>
      </div>
        <router-outlet></router-outlet>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>
